import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ระบบเกม"
    }}>{`ระบบเกม`}</h1>
    <hr></hr>
    <h3 {...{
      "id": "จิ้งจอกบุกฟาร์ม"
    }}>{`จิ้งจอกบุกฟาร์ม`}</h3>
    <p>{`จิ้งจอกจะเข้ามาบุกโจมตีฟาร์มของผู้เล่นในบางครั้ง ผู้เล่นจะต้องทำการไล่จิ้งจอกออกไปจากฟาร์มก่อน ไม่เช่นนั้นจะไม่สามารถทำฟาร์มต่อได้`}</p>
    <p><img alt="mat" src={require("./public/images/fox-1.png")} /></p>
    <p>{`การไล่จิ้งจอกออกจากฟาร์ม จะเป็นการให้ผู้เล่น เล่นมินิเกมที่เป็นการกดหน้าจอรัว ๆ ซึ่งถ้าหากผู้เล่นชนะ ก็จะสามารถไล่จิ้งจอกออกจากฟาร์มได้ แต่ถ้าผู้เล่นแพ้ จิ้งจอกจะเข้ามาป่วนฟาร์ม
จนทำให้ผลผลิตเสียหาย ทำให้รับรางวัลจากเควสได้ลดลง 30%`}</p>
    <p><img alt="mat" src={require("./public/images/fox-2.png")} /></p>
    <p>{`หากไม่อยากเล่นมินิเกม คุณสามารถจุดพลุไล่สัตว์ที่สามารถซื้อได้จากร้านค้าภายในเกม เพื่อไล่จิ้งจอกออกจากฟาร์มได้`}</p>
    <p><img alt="mat" src={require("./public/images/fox-3.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      